import { UsuarioModel } from "@/models/UsuarioModel";
import ApiService from "./ApiService";



 export const usuarioList = async ( postData: any, page = 1): Promise<Array<UsuarioModel>> => {
    const { data } = await ApiService.post(`/usuarioList?page=${page}` , postData);
    return data;
 };

export const deleteUsuario = async (postData) => {
   const { data } = await ApiService.delete(`/usuariodelete/${postData}`);
   return data;
};

export const getView = async (userId) => {
   const { data } = await ApiService.get(`/usuarioview/${userId}`);
   return data;
};

export const getAdd = async (postData: any): Promise<Array<UsuarioModel>> => {
   const { data } = await ApiService.post(`/usuarioadd`, postData );
   return data;
};

export const filtroList = async (postData: any) => {
   const { data } = await ApiService.get(`/usuario_filtroslist/${postData}` );
   return data;
};

export const storeFiltroUsuario = async (postData: any) => {
   const { data } = await ApiService.post(`/storeFiltroUsuario`,postData );
   return data;
};

export const deleteFiltroUsuario = async (postData) => {
   const { data } = await ApiService.delete(`/deleteFiltroUsuario/${postData}`);
   return data;
};



export default {usuarioList, deleteUsuario, getAdd, getView, filtroList, storeFiltroUsuario, deleteFiltroUsuario};